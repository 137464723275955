/**
 * @fileoverview Singleton wrapper for SecurePreview instance to prevent multiple instances from appending elements
 * @module WhatsAppSecurePreviewWrapper
 */

import { SecurePreview } from '@salesforce-mc/contentbuilder-sdk';
import Previewer from '@salesforce-mc/mobile-previewer';

/**
 * Singleton wrapper class for SecurePreview.
 * IMPORTANT: This class implements the singleton pattern to ensure only one instance
 * of SecurePreview exists. Multiple instances of SecurePreview will cause elements
 * to be continuously appended to the DOM, leading to performance issues and visual bugs.
 *
 * @class WhatsAppSecurePreviewWrapper
 * @example
 * // Recommended usage - using getInstance
 * const wrapper = WhatsAppSecurePreviewWrapper.getInstance(container, config);
 *
 * // Alternative usage - constructor will return singleton instance
 * const wrapper = new WhatsAppSecurePreviewWrapper(container, config);
 */
class WhatsAppSecurePreviewWrapper {
	/**
     * Creates or returns the singleton instance of WhatsAppSecurePreviewWrapper.
     * If an instance already exists, it returns the existing instance with the same SecurePreview.
     *
     * @constructor
     * @param {HTMLElement} container - The DOM element that will contain the secure preview
     * @param {Object} config - Configuration object for the preview
     * @param {string} config.stackKey - The stack key required for SecurePreview initialization
     * @returns {WhatsAppSecurePreviewWrapper} The singleton instance of WhatsAppSecurePreviewWrapper
     *
     * @example
     * const container = document.querySelector('#preview-container');
     * const config = {
     *   stackKey: 'your-stack-key'
     * };
     * const wrapper = new WhatsAppSecurePreviewWrapper(container, config);
     */
	constructor (container, config) {
		this.container = container;
		this.config = config;
        
		if (!WhatsAppSecurePreviewWrapper.instance) {
			this.securePreview = new SecurePreview(this.container, this.config.stackKey);
			WhatsAppSecurePreviewWrapper.instance = this;
		} else {
			this.securePreview = WhatsAppSecurePreviewWrapper.instance.securePreview;
		}
	}

	/**
     * Gets the singleton instance of WhatsAppSecurePreviewWrapper.
     * If no instance exists, creates one with the provided container and config.
     *
     * @static
     * @param {HTMLElement} container - The DOM element that will contain the secure preview
     * @param {Object} config - Configuration object for the preview
     * @param {string} config.stackKey - The stack key required for SecurePreview initialization
     * @returns {WhatsAppSecurePreviewWrapper} The singleton instance of WhatsAppSecurePreviewWrapper
     *
     * @example
     * const wrapper = WhatsAppSecurePreviewWrapper.getInstance(container, config);
     */
	static getInstance (container, config) {
		if (!WhatsAppSecurePreviewWrapper.instance) {
			WhatsAppSecurePreviewWrapper.instance = new WhatsAppSecurePreviewWrapper(container, config);
		}
		return WhatsAppSecurePreviewWrapper.instance;
	}

	/**
     * Renders the secure preview content.
     * Uses Previewer to get the page content and sets it in the SecurePreview instance.
     *
     * @public
     * @returns {void}
     *
     * @example
     * const wrapper = WhatsAppSecurePreviewWrapper.getInstance(container, config);
     * wrapper.render();
     */
	render () {
		const existingCrossDocIframe = this.container.querySelector('iframe:not(.meta-flows-preview)');
		if (existingCrossDocIframe) {
			existingCrossDocIframe.classList.remove('hidden');
		}
		// remove flow created dynamic elements
		[...document.querySelectorAll('iframe.meta-flows-preview, .iframe-overlay')].forEach((el) => el.remove());
		const previewer = new Previewer(this.config);
		const page = previewer.getPage() || '';
		this.securePreview.setContent(page);
	}
}

// Initialize the static instance property
WhatsAppSecurePreviewWrapper.instance = null;

export default WhatsAppSecurePreviewWrapper;
