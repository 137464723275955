import Previewer from '@salesforce-mc/mobile-previewer';
import { SecurePreview } from '@salesforce-mc/contentbuilder-sdk';
import Template from '../../templates/main.html';
import Header from './push-header';
import ByteCount from '../../utilities/byte-count';
import I18n from '../../utilities/i18n';
import DateTime from '../../utilities/date-time';
import { CAROUSEL_IMAGE_PLACEHOLDER } from '../../constants';

const push = function (dom, config) {
	const i18n = new I18n(config.locale);
	const previewerHeader = '#previewer-header';
	const previewerContainer = '#previewer-container';

	const addDefaultConfig = () => {
		const cfg = this.config;
		const { device, deviceView, isSubscriberPreview, richFeatures, template } = cfg;

		delete cfg.timestamp;
		delete cfg.action;
		delete cfg.button1;
		delete cfg.button2;
		delete cfg.mediaUrl;

		if (device === 'iphone') {
			cfg.timestamp = i18n.get('mobilepush_inapp.now');
			cfg.button1 = i18n.get('mobilepush_inapp.cancel');
			cfg.button2 = i18n.get('mobilepush_inapp.launch');
			cfg.mediaUrl = cfg.iosRichMedia || '';

			if (deviceView === 'long-press') {
				cfg.timestamp = 'x';
			}
		} else if (device === 'android') {
			cfg.mediaUrl = cfg.androidRichMedia || '';
		} else if (device === 'apple-watch') {
			cfg.button1 = i18n.get('mobilepush_inapp.dismiss');
			cfg.mediaUrl = cfg.iosRichMedia || '';
		}

		cfg.applicationName = 'APP NAME';

		if (!isSubscriberPreview) {
			if ((!cfg.title) || (cfg.title.length === 0)) {
				cfg.title = template === 'pushCarousel' && richFeatures?.viewTemplate ? '' : i18n.get('jsonmessageblock.JSONMESSAGE_PUSH_TITLE_PLACEHOLDER');
			}

			if ((!cfg.subtitle) || (cfg.subtitle.length === 0)) {
				cfg.subtitle = i18n.get('jsonmessageblock.JSONMESSAGE_SUBTITLE_PLACEHOLDER');
			}

			if ((!cfg.message) || (cfg.message.length === 0)) {
				cfg.message = i18n.get('jsonmessageblock.JSONMESSAGE_MESSAGE_PLACEHOLDER');
			}

			if (template === 'pushCarousel' && !richFeatures?.viewTemplate) {
				// Added default config for carousel template. This will be rendered at the time of template selection
				Object.assign(cfg, {
					currentColumn: 0,
					richFeatures: {
						viewTemplate: {
							type: '0',
							style: {
								backgroundColor: 'E5E5E5'
							},
							items: Array(6).fill({
								media: {
									androidMediaURL: CAROUSEL_IMAGE_PLACEHOLDER,
									iOSMediaURL: CAROUSEL_IMAGE_PLACEHOLDER
								}
							})
						}
					}
				});
			} else if (template === 'push' && richFeatures) {
				// If the template was selected from carousel to standard, we need to cleanup
				delete richFeatures.viewTemplate;
			}

			if (cfg.richFeatures) {
				// Set default media object for previewer
				const { viewTemplate } = cfg.richFeatures;
				if (template === 'pushCarousel' && viewTemplate?.type === '0' && viewTemplate.items?.length) {
					viewTemplate.items.forEach((item) => {
						if (!item.media) {
							item.media = {};
						}
						if (!item.media.altText) {
							item.media.altText = {};
						}
						if (!item.media.altText.text) {
							item.media.altText.text = i18n.get('jsonmessageblock.JSONMESSAGE_IMAGE');
						}
					});
				}
			}
		}

		if (cfg.hiddenFields.includes('title')) {
			delete cfg.title;
		}

		if (cfg.hiddenFields.includes('subtitle') || template === 'pushCarousel') {
			delete cfg.subtitle;
		}

		if (cfg.hiddenFields.includes('pushmedia')) {
			delete cfg.mediaUrl;
		}

		this.config = cfg;
	};

	const getByteCount = (content) => {
		const bytes = content || '';
		const byteCount = new ByteCount(bytes);

		return byteCount.push();
	};

	const getPage = () => {
		const previewer = new Previewer(this.config);
		const page = previewer.getPage();

		return page || '';
	};
	
	const renderSecurePreview = () => {
		const container = dom.querySelector(previewerContainer);
		
		if (!this.securePreview) {
			this.securePreview = new SecurePreview(container, config.stackKey);
		}
		
		this.securePreview.setContent(getPage());
	};
	
	const setDateTime = () => {
		const datetime = new DateTime(config.locale);
		const { device, deviceView } = this.config;
	
		this.config.date = datetime.getCurrentDate();
		this.config.time = datetime.getCurrentTime();
	
		if (device === 'android') {
			if (deviceView === 'lock-screen') {
				this.config.date = this.config.date.toUpperCase();
			}
		}
	};
	
	const renderPreviewer = () => {
		addDefaultConfig();
		setDateTime();
		renderSecurePreview();
		
		if (this.header) {
			this.header.renderByteCount();
		}
	};

	const renderHeader = () => {
		const container = dom.querySelector(previewerHeader);

		this.config.i18n = i18n;

		this.header = new Header(container, this.config, ((response) => {
			const { device, deviceType } = response;

			this.config.device = device;
			this.config.deviceView = deviceType;
			this.config.byteCount = getByteCount(this.config);

			renderPreviewer();
		}));
	};

	const render = () => {
		dom.innerHTML = Template;
	};

	const initialize = () => {
		this.config = config || {};
		this.config.byteCount = getByteCount(this.config);

		render();
		renderHeader();
	};

	initialize();

	this.render = (updates) => {
		this.config = Object.assign(this.config, updates);
		this.config.byteCount = getByteCount(this.config);
		this.header.renderByteCount(config.byteCount);

		renderPreviewer();
	};
};

export default push;
