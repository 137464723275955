import Template from '../../../templates/whatsapp-header.html';

const whatsappHeader = function (dom, config) {
	const { i18n } = config;

	const addEventListeners = () => {
		dom.querySelector('#portrait').addEventListener('click', (event) => {
			dom.querySelector('#landscape').classList.remove('slds-is-selected');
			
			event.currentTarget.classList.add('slds-is-selected');
			
			this.onClickOrientation('portrait');
		});
		
		dom.querySelector('#portrait').addEventListener('mouseover', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.remove('hidden');
		});
		
		dom.querySelector('#portrait').addEventListener('mouseout', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.add('hidden');
		});
		
		dom.querySelector('#landscape').addEventListener('click', (event) => {
			dom.querySelector('#portrait').classList.remove('slds-is-selected');
			
			event.currentTarget.classList.add('slds-is-selected');
			
			this.onClickOrientation('landscape');
		});
		
		dom.querySelector('#landscape').addEventListener('mouseover', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.remove('hidden');
		});
		
		dom.querySelector('#landscape').addEventListener('mouseout', (event) => {
			event.currentTarget.parentElement.querySelector('.slds-popover').classList.add('hidden');
		});
	};

	const isFlowsMessageType = () => config?.type === 'flow' || config?.buttons?.regular?.[0]?.actionType === 'FLOW';
	
	const isFlowsMetaPreview = () => isFlowsMessageType() && config?.previewType === 'metaPreview';
	
	const updateOrientationControlsVisibility = () => {
		const orientationControls = dom.querySelector('#orientation-controls');
		if (orientationControls) {
			if (isFlowsMetaPreview()) {
				orientationControls.classList.add('hidden');
			} else {
				orientationControls.classList.remove('hidden');
			}
		}
	};

	const setupPreviewTypeRadioGroup = () => {
		const previewContainer = dom.querySelector('#flow-preview-control-container');
		const flowMessage = dom.querySelector('#flow-message-preview');

		if (isFlowsMetaPreview() && flowMessage) {
			flowMessage.checked = true;
		}

		if (previewContainer) {
			if (isFlowsMessageType()) {
				previewContainer.classList.remove('hidden');
			} else {
				previewContainer.classList.add('hidden');
			}
			previewContainer.addEventListener('change', (event) => {
				const { type, name, value } = event.target;
				if (type === 'radio' && name === 'preview-type') {
					event.stopPropagation();
					config.previewType = value;
					updateOrientationControlsVisibility();
					if (this.onFlowPreviewTypeChange) {
						this.onFlowPreviewTypeChange(value);
					}
				}
			});
		}
	};
	
	// const renderScreenPreviews = () => {
	// 	const container = dom.querySelector('#screen-preview-container');
	// 	const selection = {
	// 		value: 'app',
	// 		text: i18n.get('jsonmessageblock.JSONMESSAGE_WHATSAPP_APP_SCREEN_PREVIEW')
	// 	};
	// 	const comboboxConfig = {
	// 		id: 'screen-preview',
	// 		options: [
	// 			selection,
	// 			{
	// 				value: 'lock',
	// 				text: i18n.get('jsonmessageblock.JSONMESSAGE_WHATSAPP_LOCK_SCREEN_PREVIEW')
	// 			}
	// 		],
	// 		selection
	// 	};
	//
	// 	this.screenPreview = new Combobox(container, comboboxConfig);
	//
	// 	this.screenPreview.onSelect = (response) => {
	// 		this.onSelectScreenPreview(response.value);
	// 	};
	// };
	
	const render = () => {
		let template = Template;
		
		template = template.replace(new RegExp('{portrait}', 'g'), i18n.get('mobilepush_inapp.portrait'));
		template = template.replace(new RegExp('{landscape}', 'g'), i18n.get('mobilepush_inapp.landscape'));
		template = template.replace('{display-preview}', (isFlowsMessageType() ? '' : i18n.get('mobilepush_inapp.display_previewer')));
		template = template.replace('{base-message-preview}', i18n.get('mobilepush_whatsapp.JSONMESSAGE_FLOWS_BASE_MESSAGE_BTN_GROUP_LABEL'));
		template = template.replace('{flow-message-preview}', i18n.get('mobilepush_whatsapp.JSONMESSAGE_FLOWS_BASE_MESSAGE_FLOW_GROUP_LABEL'));
		
		dom.innerHTML = template;
		setupPreviewTypeRadioGroup();
		updateOrientationControlsVisibility();
		
		// TODO: Enable screen previews in the later releases. Commenting out for Pilot and GA.
		// renderScreenPreviews();
	};
	
	const initialize = () => {
		this.config = config || {};
		
		render();
		addEventListeners();
	};
	
	initialize();
};

export default whatsappHeader;
