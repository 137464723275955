/**
 * @fileoverview DefaultHeader class that wraps the standard WhatsApp Header component
 * @module DefaultHeader
 */

import Header from './whatsapp-header';

/**
 * Wrapper class for the standard WhatsApp Header component.
 * Provides a consistent interface with other header implementations
 * while delegating functionality to the underlying Header class.
 *
 * @class DefaultHeader
 * @example
 * const header = new DefaultHeader(container, {
 *   orientation: 'portrait',
 *   i18n: i18nInstance
 * });
 * header.render();
 */
class DefaultHeader {
	/**
     * Creates an instance of DefaultHeader.
     * Initializes the underlying WhatsApp Header component.
     *
     * @constructor
     * @param {HTMLElement} container - The DOM element that will contain the header
     * @param {Object} config - Configuration object for the header
     * @param {string} [config.orientation='portrait'] - The orientation of the preview
     * @param {Object} config.i18n - Internationalization object for translations
     * @param {string} [config.screenPreview] - The screen preview type
     */
	constructor (container, config) {
		/**
         * Instance of the underlying WhatsApp Header component
         * @private
         * @type {Header}
         */
		this.header = new Header(container, config);
	}

	/**
     * Renders the header component.
     * Currently a placeholder as rendering is handled by the Header class constructor.
     *
     * @public
     * @returns {void}
     */
	render () {
		// Implement rendering logic if needed
		// Note: Currently, rendering is handled by the Header class constructor
	}

	/**
     * Setter for orientation change callback.
     * Maps to the onClickOrientation callback of the underlying Header component.
     *
     * @public
     * @param {Function} callback - The callback function to handle orientation changes
     * @param {string} callback.orientation - The new orientation value ('portrait' or 'landscape')
     * @example
     * header.onOrientationChange = (orientation) => {
     *   console.log(`Orientation changed to: ${orientation}`);
     * };
     */
	set onOrientationChange (callback) {
		this.header.onClickOrientation = callback;
	}

	/**
     * Setter for screen preview change callback.
     * Maps to the onSelectScreenPreview callback of the underlying Header component.
     *
     * @public
     * @param {Function} callback - The callback function to handle screen preview changes
     * @param {string} callback.previewType - The new preview type
     * @example
     * header.onScreenPreviewChange = (previewType) => {
     *   console.log(`Preview type changed to: ${previewType}`);
     * };
     */
	set onScreenPreviewChange (callback) {
		this.header.onSelectScreenPreview = callback;
	}

	set onFlowPreviewTypeChange (callback) {
		this.header.onFlowPreviewTypeChange = callback;
	}
}

export default DefaultHeader;
