import { PUSH_DESIGN_DEFAULTS } from '../../constants';
import output from '../output';
/* eslint-disable import/prefer-default-export */
const hasDiffValueThanDefault = (defaultValue, value) => defaultValue !== value;

const getTextWithStyleObj = (field, payloadKey, designKey) => {
	const { text = '', style } = field;
	if (!text) return '';
	
	const textWithStyleFields = [];
	textWithStyleFields.push(`"tx":"${text}"`);
	
	const syleFields = [];
	if (style && Object.keys(style).length) {
		const { fontColor, fontSize, fontStyle, alignment } = style;
		const defaultDesignObj = PUSH_DESIGN_DEFAULTS[designKey] || {};
		if (hasDiffValueThanDefault(defaultDesignObj.fontColor, fontColor)) {
			syleFields.push(`"fc":"${fontColor}"`);
		}
		if (hasDiffValueThanDefault(defaultDesignObj.fontStyle, fontStyle)) {
			syleFields.push(`"fs":"${fontStyle}"`);
		}
		if (hasDiffValueThanDefault(defaultDesignObj.fontSize, fontSize)) {
			syleFields.push(`"fz":"${fontSize}"`);
		}
		if (hasDiffValueThanDefault(defaultDesignObj.alignment, alignment)) {
			syleFields.push(`"an":"${alignment}"`);
		}
		if (syleFields.length) {
			textWithStyleFields.push(`"s":{${syleFields.join(',')}}`);
		}
	}
	
	return `"${payloadKey}":{${textWithStyleFields.join(',')}}`;
};

const getActionsObj = (actions) => {
	const actionsArr = [];
	
	actions.forEach((action) => {
		const { type, url } = action;
		
		const actionFields = [];
		actionFields.push(`"t":"${type}"`);
		
		if (url) {
			actionFields.push(`"url":"${url}"`);
		}
		
		actionsArr.push(`{${actionFields.join(',')}}`);
	});
	
	if (actionsArr.length) {
		return `"ac":[${actionsArr.join(',')}]`;
	}
	
	return '';
};

const getViewTemplateObj = (viewTemplate, device) => {
	const { type, items, style: { backgroundColor } } = viewTemplate;
	
	const viewTemplateFields = [];
	viewTemplateFields.push(`"t":"${type}"`);
	
	const bgColor = hasDiffValueThanDefault(PUSH_DESIGN_DEFAULTS.imageBackgroundColor, backgroundColor) ? `"bgc":"${backgroundColor}"` : '';
	if (bgColor) {
		viewTemplateFields.push(`"s":{${bgColor}}`);
	}
	
	const itemsArr = [];
	if (items?.length) {
		items.forEach((item) => {
			const { identifier, media, title, subTitle, actions } = item;
			
			const itemFields = [];
			itemFields.push(`"id":"${identifier}"`);
			
			if (media) {
				const { androidMediaURL, iOSMediaURL, altText } = media;
				const mediaFields = [];
				mediaFields.push(`"ul":"${device === 'android' ? androidMediaURL : iOSMediaURL}"`);
				const altTextObj = getTextWithStyleObj(altText, 'at');
				if (altTextObj) {
					mediaFields.push(altTextObj);
				}
				itemFields.push(`"md":{${mediaFields.join(',')}}`);
			}
			
			const titleObj = getTextWithStyleObj(title, 'ti', 'title');
			if (titleObj) {
				itemFields.push(titleObj);
			}
			
			const subTitleObj = getTextWithStyleObj(subTitle, 'sti', 'subTitle');
			if (subTitleObj) {
				itemFields.push(subTitleObj);
			}
			
			if (actions?.length) {
				itemFields.push(getActionsObj(actions));
			}
			itemsArr.push(`{${itemFields.join(',')}}`);
		});
	}
	
	if (itemsArr.length) {
		viewTemplateFields.push(`"it":[${itemsArr.join(',')}]`);
	}
	
	return `"vt":{${viewTemplateFields.join(',')}}`;
};

const getButtonsObj = (buttons, device) => {
	if (device !== 'android') return '';
	
	let buttonsObj = '';
	const buttonsArr = [];
	
	if (buttons?.length) {
		buttons.forEach((button) => {
			const { identifier, title, icon, actions } = button;
			
			const buttonFields = [];
			buttonFields.push(`"id":"${identifier}"`);
			const buttonTitleObj = getTextWithStyleObj(title, 'ti', 'button');
			if (buttonTitleObj) {
				buttonFields.push(buttonTitleObj);
			}
			if (icon) {
				buttonFields.push(`"ic":"${icon}"`);
			}
			if (actions?.length) {
				buttonFields.push(getActionsObj(actions));
			}
			
			buttonsArr.push(`{${buttonFields.join(',')}}`);
		});
	}
	
	if (buttonsArr.length) {
		buttonsObj = `"btn":[${buttonsArr.join(',')}]`;
	}
	
	return buttonsObj;
};

export const getRichFeaturesObj = (richFeatures, device) => {
	try {
		const { viewTemplate, buttons, smallIcon, largeIcon } = richFeatures;
		const richFeaturesFields = [];
		const isAndroid = device === 'android';
		
		if (isAndroid && smallIcon) {
			richFeaturesFields.push(`"sic":"${smallIcon}"`);
		}
		if (isAndroid && largeIcon) {
			richFeaturesFields.push(`"lic":"${largeIcon}"`);
		}
		if (viewTemplate) {
			richFeaturesFields.push(getViewTemplateObj(viewTemplate, device));
		}
		if (isAndroid && buttons?.length) {
			richFeaturesFields.push(getButtonsObj(buttons, device));
		}
		
		return `"_rf":{${richFeaturesFields.join(',')}}`;
	} catch (e) {
		output.error('Failed to get byte count\n', e);
		return '';
	}
};
