/**
 * @fileoverview Factory class for creating different types of WhatsApp preview headers
 * @module HeaderFactory
 */

import DefaultHeader from './default-header';

/**
 * Factory class responsible for creating appropriate header instances based on the preview type.
 * During development, the header type can be overridden using localStorage.
 *
 * @class HeaderFactory
 * @static
 * @example
 * // Normal usage
 * const header = HeaderFactory.createHeader('flow', containerElement, configObject);
 *
 * // Development override
 * localStorage.setItem('previewerType', 'flow');
 * const header = HeaderFactory.createHeader('default', containerElement, configObject);
 */
class HeaderFactory {
	/**
     * Creates and returns an instance of the appropriate header based on the type.
     * The type can be overridden during development by setting 'previewerType' in localStorage.
     *
     * @static
     * @param {string} type - The type of header to create ('flow' or 'default')
     * @param {HTMLElement} container - The DOM element that will contain the header
     * @param {Object} config - Configuration object for the header
     * @param {string} [config.orientation] - The orientation of the preview ('portrait' or 'landscape')
     * @param {string} [config.screenPreview] - The screen preview type
     * @param {Object} [config.i18n] - Internationalization object for translations
     * @returns {(DefaultHeader)} An instance of the appropriate header class
     *
     * @example
     * // Basic usage
     * const container = document.querySelector('#header-container');
     * const config = {
     *   orientation: 'portrait',
     *   i18n: i18nInstance
     * };
     * const header = HeaderFactory.createHeader('flow', container, config);
     *
     * @example
     * // Development override
     * localStorage.setItem('previewerType', 'flow');
     * const header = HeaderFactory.createHeader('default', container, config);
     */
	static createHeader (container, config) {
		return new DefaultHeader(container, config);
	}
}

export default HeaderFactory;
