/**
 * @fileoverview Factory class for creating different types of previewers
 * @module PreviewerFactory
 */

import SecurePreviewWrapper from './secure-preview-wrapper';
import WhatsAppFlowsPreview from './whatsapp-flows-preview';

/**
 * Factory class responsible for creating appropriate previewer instances based on the type.
 * During development, the previewer type can be overridden using localStorage.
 *
 * @class PreviewerFactory
 * @static
 * @example
 * // Normal usage
 * const previewer = PreviewerFactory.createPreviewer('flow', containerElement, configObject);
 *
 * // Development override
 * localStorage.setItem('previewerType', 'flow');
 * const previewer = PreviewerFactory.createPreviewer('default', containerElement, configObject);
 */
class PreviewerFactory {
	/**
     * Creates and returns an instance of the appropriate previewer based on the type.
     * The type can be overridden during development by setting 'previewerType' in localStorage.
     *
     * @static
     * @param {string} type - The type of previewer to create ('flow' or 'default')
     * @param {HTMLElement} container - The DOM element that will contain the preview
     * @param {Object} config - Configuration object for the previewer
     * @param {string} [config.orientation] - The orientation of the preview ('portrait' or 'landscape')
     * @param {string} [config.stackKey] - The stack key required for SecurePreview
     * @returns {(WhatsAppFlowsPreview|SecurePreviewWrapper)} An instance of the appropriate previewer
     *
     * @example
     * const container = document.querySelector('#preview-container');
     * const config = {
     *   orientation: 'portrait',
     *   stackKey: 'your-stack-key'
     * };
     * const previewer = PreviewerFactory.createPreviewer('image', container, config);
     */
	static createPreviewer (container, config) {
		switch (config?.previewType) {
			case 'metaPreview':
				return new WhatsAppFlowsPreview(container, config);
			default:
				return new SecurePreviewWrapper(container, config);
		}
	}
}

export default PreviewerFactory;
