const urlRegex = /https?:\/\/[^\s]+/g;
const sfmcUrlToReplace = '//x.sfmsg.co/xxxxxxxxx';
const AMPSCRIPT_REGEX = /%%.*?%%/gs;
const SSJS_REGEX = /<script[\s\S]*?>[\s\S]*?<\/script>/gi;
const SFMC_OPTION = 'SFMC';

const replaceUrls = (text, dummyUrlPath) => text.replace(urlRegex, (match) => {
	try {
		const url = new URL(match);

		// Check if dummyUrlPath has a protocol (starts with "http://" or "https://")
		const dummyUrl = dummyUrlPath.startsWith('http://') || dummyUrlPath.startsWith('https://')
			? dummyUrlPath
			: url.protocol + dummyUrlPath;

		return dummyUrl;
	} catch (error) {
		// Return the original match if URL parsing fails
		return match;
	}
});

const getReplacedMessage = (message, dummyUrlPath) => {
	// Find all AMPscript and SSJS blocks,
	const ampScriptMatches = [...message.matchAll(AMPSCRIPT_REGEX)];
	const ssjsMatches = [...message.matchAll(SSJS_REGEX)];

	// Combine all matches to a single array and sort them by their position in the message
	const allMatches = [...ampScriptMatches, ...ssjsMatches].sort((a, b) => a.index - b.index);

	let replacedMessage = '';
	let lastIndex = 0;

	allMatches.forEach((match) => {
		// Add the part of the message before the current AMPscript/SSJS block
		const textBeforeMatch = message.substring(lastIndex, match.index);
		replacedMessage += replaceUrls(textBeforeMatch, dummyUrlPath);

		// Add the AMPscript/SSJS block unchanged
		replacedMessage += match[0];
		lastIndex = match.index + match[0].length;
	});

	// Handle the last part of the message after the final AMPscript/SSJS block
	replacedMessage += replaceUrls(message.substring(lastIndex), dummyUrlPath);

	return replacedMessage;
};

const replaceUrlsInMessageString = (message, isLinkShorteningEnabled = false, shortenerType = SFMC_OPTION, customDomain = '') => {
	if (message && isLinkShorteningEnabled) {
		if (shortenerType === SFMC_OPTION) {
			return customDomain
				? getReplacedMessage(message, `//${customDomain}/xxxxxxxxx`)
				: getReplacedMessage(message, sfmcUrlToReplace);
		}
		return message;
	}
	return message;
};

export default replaceUrlsInMessageString;
